import React from "react";

const Code = () => (
  <>
    <p>Let's code</p>
  </>
);

Code.route = {
  path: "/code"
};

export default Code;
